<template>
    <div class="CardsInfo" v-if="row&&row.reportTime">
        <div class="CardsInfo-header">上报时间：{{row.reportTime}}</div>

        <el-form  label-position="left" label-width="250px">
            <el-form-item v-for="(item,index) in row.dynamicFormBusinessVos" :key="index" :label="`${item.fieldName}：`">

                <div v-if="/\.(png|jpg)/.test(item.properties)">
                    <el-image   :preview-src-list="imgs"
                                style="width: 80px; height: 80px;margin-left: 20px"
                                :src="imgs[0]"
                                fit="fill">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size: 50px;color:#999"></i>
                        </div>
                    </el-image>
                </div>

                <div  v-else style="padding-left: 20px">
                    {{item.properties|properties_filters}}
                </div>


            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {fileUrl} from "@/api/Global.js";
export default {
    name: "CardsInfo",
    props:{
        row:{
            type:Object,
            default:()=>null
        }
    },

    computed:{
        fileUrl(){
            return fileUrl
        },
        imgs(){
            let arr = this.row.dynamicFormBusinessVos.filter(
                item=>  /\.(png|jpg)/.test(item.properties)
                    // item.fileValue==='picture'||item.fileValue==='图片'
            )[0];
            let properties = [];
            if(arr.properties){
                properties = arr.properties.split(",")
            }
            properties.forEach((item,index)=>{
                properties[index] = this.fileUrl+item
                }
            );
            return properties
        }
    },
    filters:{
        properties_filters(val){
            return val.split("$")[0]
        },
    }
}
</script>

<style scoped lang="scss">
.CardsInfo{
  background: #F7F8FB;
  border-radius: 16px;
    box-sizing: border-box;
    padding: 20px  0 20px 100px;
    .CardsInfo-header{
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding-bottom: 10px;
      position: relative;
      text-indent: 25px;
    }
  .CardsInfo-header:after{
    width: 16px;
    height: 16px;
    background: #487EE0;
    border-radius: 50%;
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
  }

    .el-form-item{
        //margin-bottom: 5px!important;
        ::v-deep .el-form-item__label{
          line-height: 18px;
          font-size: 14px;
          color: #666666;
        }
        ::v-deep .el-form-item__content{
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
          color: #333333;
        }
    }

  .el-image{
    background-color:rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>