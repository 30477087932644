<template>
    <div class="dashboard-container">
        <el-card v-if="tableShow">
            <div class="flex-row-box">
                <div class="pl-1 pr-1">
                    <span class="text-primary text-pad-right">日期</span>
                    <el-date-picker
                        v-model="condition.date"
                        type="daterange"
                        class="input searchInput"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        clearable
                        value-format="yyyy-MM-dd"
                        size="mini"
                        @change="changeTime"
                    />
                </div>
                <div v-if="companyType" class="pl-1 pr-1">
                    <span class="text-primary text-pad-right">企业名称</span>
                    <el-select
                        v-model="companyId"
                        filterable
                        size="mini"
                        class="input searchInput"
                        @change="changeCondition"
                    >
                        <el-option
                            v-for="(item, index) in company"
                            :key="index"
                            :value="item.id"
                            :label="item.companyName"
                        />
                    </el-select>
                </div>
                <div class="pl-1 pr-1">
                    <span class="text-primary text-pad-right">部门</span>
                    <!-- <el-select v-model="condition.departId" clearable filterable size="mini" class="input searchInput" @change="changeJob">
						<el-option v-for="(item,index) in depart" :key="index" :value="item.id" :label="item.departName" />
					</el-select> -->
                    <el-cascader
                        ref="dept"
                        v-model="condition.departId"
                        :options="depart"
                        class="input searchInput"
                        size="mini"
                        :props="{
                            expandTrigger: 'hover',
                            value: 'id',
                            children: 'sons',
                            label: 'departName',
                            emitPath: false,
                            checkStrictly: true,
                        }"
                        filterable
                        clearable
                        @change="changeJob"
                    />
                </div>
                <div class="pl-1 pr-1">
                    <span class="text-primary text-pad-right">岗位</span>
                    <el-select
                        v-model="condition.positionId"
                        clearable
                        filterable
                        size="mini"
                        class="input searchInput"
                        @change="changeJob"
                    >
                        <el-option
                            v-for="(item, index) in position"
                            :key="index"
                            :value="item.id"
                            :label="item.positionName"
                        />
                    </el-select>
                </div>
            </div>
        </el-card>
        <el-card style="margin-top: 20px" v-if="tableShow">
            <el-row>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col></el-col>
                            <el-col :span="3">
                                <vxe-button @click="shuaxin">刷新</vxe-button>
                                <vxe-button @click="defenseExport"
                                    >导出Excel</vxe-button
                                >
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="620"
                    :data="table.list"
                    :loading="loading"
                    @cell-click="cellClick"
                >
                    <vxe-table-column
                        title="时间"
                        field="reportTimeStr"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        title="已上报（人）"
                        field="reportedNum"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        title="未上报（人）"
                        field="notReportedNum"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        title="上报率（%）"
                        field="reportingRate"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        title="异常人数"
                        field="abnormalNum"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        title="正常人数"
                        field="normalNum"
                        show-overflow="title"
                    />
                </vxe-table>
                <div style="height: 20px" />
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-row>
        </el-card>
        <div v-if="!tableShow">
            <vxe-toolbar>
                <template v-slot:buttons>
                    <el-row type="flex" justify="between">
                        <el-col>
                            <vxe-button @click="tableShow = true"
                                >返回</vxe-button
                            >
                        </el-col>
                        <el-col :span="3">
                            <vxe-button @click="defenseExport1"
                                >导出Excel</vxe-button
                            >
                            <vxe-button @click="shuaxin1">刷新</vxe-button>
                        </el-col>
                    </el-row>
                </template>
            </vxe-toolbar>
            <div>
                <el-row type="flex">
                    <el-col :span="24" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">
                            {{ companyName ? companyName : "--" }}
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex">
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">上报时间</div>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">
                            {{ createTime ? createTime : "--" }}
                        </div>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">上报率（%）</div>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">
                            {{ reportingRate ? reportingRate : "0" }}
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex">
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">已上报（人）</div>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">
                            {{ reportedNum ? reportedNum : "0" }}
                        </div>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">未上报（人）</div>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">
                            {{ notReportedNum ? notReportedNum : "0" }}
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex">
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">异常人数（人）</div>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">
                            {{ abnormalNum ? abnormalNum : "0" }}
                        </div>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">正常人数（人）</div>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #e2e2e2">
                        <div class="cell-outer">
                            {{ normalNum ? normalNum : "0" }}
                        </div>
                    </el-col>
                </el-row>
            </div>
            <vxe-table
                ref="table"
                border
                resizable
                :auto-resize="true"
                :header-row-style="headerStyle1"
                align="center"
                class="vxe-table-element"
                height="560"
                :data="table1.list"
                :loading="loading"
            >
                <vxe-table-column type="expand" title="#">
                    <template #default="{ row }"></template>
                    <template #content="{ row, rowIndex }">
                        <!--                        {{row.epidemicPreventionDayDetailsVos}}-->
                        <!--						<div style="padding-bottom: 15px;border-bottom: 1px solid #E2E2E2;padding-top: 15px;" v-for="(item,index) in row.epidemicPreventionDayDetailsVos"-->
                        <!--						 :key="index">-->
                        <!--							<div style="padding-bottom: 10px;font-weight: bold;"><i class="el-icon-star-on" style="color: #007AFF;"></i>{{item.fieldName}}</div>-->
                        <!--							<div>{{item.properties}}</div>-->
                        <!--						</div>-->
                        <SwiperCards :row="row" />
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    title="姓名"
                    field="realName"
                    show-overflow="title"
                />
                <vxe-table-column
                    title="部门"
                    field="departName"
                    show-overflow="title"
                />
                <vxe-table-column
                    title="联系电话"
                    field="phone"
                    show-overflow="title"
                />
                <vxe-table-column
                    title="上报次数"
                    field="reportNum"
                    show-overflow="title"
                />
                <vxe-table-column
                    title="上报状态"
                    field="isNormalStr"
                    show-overflow="title"
                />
            </vxe-table>
            <div style="height: 20px" />
            <el-pagination
                background
                :current-page="table1.currentPage"
                :page-sizes="[5, 10, 15, 20, 50, 100]"
                :page-size="table1.pageSize"
                layout="total, ->, sizes, prev, pager, next, jumper"
                :total="table1.total"
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
            />
        </div>
    </div>
</template>

<script>
import SwiperCards from "./components/SwiperCards.vue";
import { bytesToFile } from "@/utils/handleByte";
import { rowStyle, headerStyle, headerStyle1 } from "@/utils/tableStyleJs";
import { company, depart, dropUserPosition } from "@/api";
export default {
    components: { SwiperCards },

    name: "Index",
    data() {
        return {
            loading: false,
            condition: {
                releaseStatus: [],
                search: "",
                date: [],
                start: "",
                end: "",
                departId: null,
                positionId: null,
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 20,
                sortName: "",
                sortBy: "",
            },
            table1: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                show: false,
            },
            company: [],
            position: [],
            depart: [],
            loginInfo: {},
            companyId: this.$admin ? null : this.$companyId,
            warnType: [],
            companyType: false,
            tableShow: true,
            reportingRate: "",
            reportedNum: "",
            createTime: "",
            notReportedNum: "",
            normalNum: "",
            companyName: "",
            abnormalNum: "",
            dynamicFormFieldVos: [],
        };
    },
    created() {
        this.getCompany();
        if (this.$companyType == 0) {
            this.companyType = true;
        } else {
            this.companyType = false;
        }
    },
    mounted() {
        // if (!this.$admin) {
        //     this.getDepart();
        //     this.getPosition();
        // }
    },
    methods: {
        rowStyle,
        headerStyle1,
        headerStyle,
        changeCondition() {
            this.tableShow = true;
            this.table.currentPage = 1;
            this.table.list = [];
            this.getDepart();
            this.getPosition();
            this.getList();
        },
        changeJob() {
            this.tableShow = true;
            this.table.currentPage = 1;
            this.table.list = [];
            this.getList();
        },
        getDepart() {
            this.$axiosReq(
                depart,
                null,
                {
                    companyId: this.companyId,
                    type: 1,
                },
                "get"
            ).then((res) => {
                this.depart = res.data;
            });
        },
        getPosition() {
            this.$axiosReq(
                dropUserPosition,
                null,
                {
                    companyId: this.companyId,
                },
                "get"
            ).then((res) => {
                this.position = res.data;
            });
        },
        shuaxin() {
            this.table.currentPage = 1;
            // this.companyId = '';
            this.condition.start = "";
            this.condition.end = "";
            this.condition.search = "";
            this.condition.releaseStatus = [];
            this.condition.date = [];
            this.tableShow = true;
            this.table.list = [];
            this.getList();
        },
        shuaxin1() {
            this.table1.currentPage = 1;
            this.table1.list = [];
            this.getHealthWarning();
        },
        defenseExport() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$axiosReq(
                "/archives/server/escort/web/epidemic/prevention/export",
                null,
                {
                    companyId: this.companyId,
                    departId: this.condition.departId,
                    positionId: this.condition.positionId,
                    beginTime: this.condition.start
                        ? this.condition.start + " 00:00:00"
                        : null,
                    endTime: this.condition.end
                        ? this.condition.end + " 00:00:00"
                        : null,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally(() => {
                    loading.close();
                });
        },
        defenseExport1() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$axiosReq(
                "/archives/server/escort/web/epidemic/prevention/details/export",
                null,
                {
                    companyId: this.companyId,
                    departId: this.condition.departId,
                    positionId: this.condition.positionId,
                    reportTime: this.createTime
                        ? this.createTime + " 00:00:00"
                        : null,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally(() => {
                    loading.close();
                });
        },
        changeTime(v) {
            this.table.currentPage = 1;
            this.tableShow = true;
            this.table.list = [];
            if (v === null) {
                this.condition.start = "";
                this.condition.end = "";
            } else {
                this.condition.start = v[0];
                this.condition.end = v[1];
            }
            this.getList();
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                "/archives/server/escort/web/epidemic/prevention",
                null,
                {
                    companyId: this.companyId,
                    departId: this.condition.departId,
                    positionId: this.condition.positionId,
                    startTime: this.condition.start
                        ? this.condition.start + " 00:00:00"
                        : null,
                    endTime: this.condition.end
                        ? this.condition.end + " 00:00:00"
                        : null,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(Number(res.data.totalElements));
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getHealthWarning() {
            this.loading = true;
            this.$axiosReq(
                "/archives/server/escort/web/epidemic/prevention/details",
                null,
                {
                    companyId: this.companyId,
                    departId: this.condition.departId,
                    positionId: this.condition.positionId,
                    reportTime: this.createTime
                        ? this.createTime + " 00:00:00"
                        : null,
                    currentPage: this.table1.currentPage,
                    pageSize: this.table1.pageSize,
                },
                "get"
            )
                .then((res) => {
                    this.table1.list = res.data.pageVo.content;
                    this.table1.total = Number(res.data.pageVo.totalElements);
                    this.reportingRate = res.data.reportingRate;
                    this.reportedNum = res.data.reportedNum;
                    this.createTime = res.data.reportTimeStr;
                    this.notReportedNum = res.data.notReportedNum;
                    this.normalNum = res.data.normalNum;
                    this.companyName = res.data.companyName;
                    this.abnormalNum = res.data.abnormalNum;
                    this.dynamicFormFieldVos = res.data.dynamicFormFieldVos;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    currentPage: 1,
                    pageSize: 1000,
                },
                "get"
            ).then((res) => {
                if (res.data.content && res.data.content.length > 0) {
                    this.company = res.data.content;
                    this.companyId = res.data.content[0].id;
                    this.getDepart();
                    this.getPosition();
                    this.getList();
                }
            });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
        handleSizeChange1(pageSize) {
            this.table1.pageSize = pageSize;
            this.table1.currentPage = 1;
            this.getHealthWarning();
        },
        handleCurrentChange1(currentPage) {
            this.table1.currentPage = currentPage;
            this.getHealthWarning();
        },
        refreshDialog() {
            this.dialog.refresh = false;
        },
        cellClick(v) {
            this.tableShow = false;
            this.createTime = v.row.reportTimeStr;
            this.table1.currentPage = 1;
            this.table1.list = [];
            this.getHealthWarning();
        },
    },
};
</script>

<style scoped>
.cell-outer {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
}

.listBox {
    height: 525px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    border-left: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
}

.listBox::-webkit-scrollbar {
    display: none;
}

.flexBoxRow {
    height: 45px;
    line-height: 45px;
    text-align: center;
    border: 1px solid #e2e2e2;
}
.swiper-button-next,
.swiper-button-prev {
    /*display: none;*/
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
    display: block;
}
</style>
