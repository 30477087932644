<template>
    <div class="SwiperCards">
        <el-carousel indicator-position="outside" :interval="20000" height="650px">
            <el-carousel-item v-for="(item,index) in epidemicPreventionDayDetailsVos" :key="index">
                <div class="SwiperCards-wrap">
                    <div class="SwiperCards-context">
                        <CardsInfo :row="item[0]"/>
                    </div>
                    <div class="SwiperCards-context">
                     <CardsInfo  :row="item[1]"/>
                    </div>
                </div>

            </el-carousel-item>
        </el-carousel>

    </div>
</template>

<script>
function arrTrans(num, arr) {
    const iconsArr = [];
    arr.forEach((item, index) => {
        const page = Math.floor(index / num);
        if (!iconsArr[page]) {
            iconsArr[page] = [];
        }
        iconsArr[page].push(item);
    });
    return iconsArr;
}
import CardsInfo from "./CardsInfo.vue"
export default {
    components:{CardsInfo},
    name: "SwiperCards",
    props:{
        row:{
            type:Object,
            default:()=>null
        }
    },
    computed:{
        epidemicPreventionDayDetailsVos(){
            let arr = [];
            if(this.row.epidemicPreventionDayDetailsVos.length%2!==0){
                this.row.epidemicPreventionDayDetailsVos.push({});
            }
            let length = this.row.epidemicPreventionDayDetailsVos.length/2;
            let epidemicPreventionDayDetailsVos  = this.row.epidemicPreventionDayDetailsVos;


          arr = arrTrans(2,epidemicPreventionDayDetailsVos)

            // epidemicPreventionDayDetailsVos.forEach((item,index)=>{
            //     if(2%index===0){
            //
            //     }
            // });
            //
            // for(let i=0;i < length ;i++ ){
            //     arr.push({
            //         1:epidemicPreventionDayDetailsVos[i],
            //         2:epidemicPreventionDayDetailsVos[i+1]
            //     });
            // }
            console.log(arr)
            return arr;
        }
    }
}
</script>

<style scoped lang="scss">
.SwiperCards{
  box-sizing: border-box;
  padding: 20px 50px 0;
  //height: 360px;
  overflow-x: hidden;
    .SwiperCards-wrap{
      display: flex;
      .SwiperCards-context{
        margin: 0 auto;
        list-style: none;
        width: 700px;
        //height: 350px;
        //background: #F7F8FB;
      }
    }

}
.el-descriptions{
  box-sizing: border-box;
  padding: 20px;
}
::v-deep .el-descriptions__body{
  background-color: transparent!important;
}

</style>